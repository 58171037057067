@import '../../style/_mixins';

.record {
	display: grid;
	grid-template-rows: 1fr 160px;
	padding-top: 24px;
	// width: 80%;
	max-width: var(--largeMin);
	margin: 0 auto;
	// background: rebeccapurple;

	--popupZIndex: 100000;
}

.portrait {
	.stage-container {
		// max-width: 1080px;
		// max-height: 1920px;
		aspect-ratio: 9/16;
	}
}

.landscape {
	.stage-container {
		// max-width: 1920px;
		// max-height: 1080px;
		aspect-ratio: 16/9;
	}
}

.default-state {
	.footer-container {
		grid-template-rows: 1fr;
	}
}

.stage-container {
	margin: auto;
	background: rebeccaPurple;
	max-height: 100%;
	max-width: 100%;
	border-radius: 8px;
	box-shadow: var(--boxShadow);
	overflow: hidden;
}

.footer-container {
	display: grid;
	grid-template-rows: 75px 1fr;
	padding: 10px 0;
}

.clips-filmstrip-container {
	position: absolute;
	top: 25px;
	left: 25px;
	width: calc(100% - 50px);
	display: flex;
	flex-direction: column;
	gap: 8px;
}

.exporter-container {
	position: absolute;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
	z-index: 1000;
}

.active-new-content-drag {
	&::before {
		content: '';
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background: linear-gradient(
			0deg,
			rgba(182, 32, 224, 0.17) -40%,
			rgba(168, 49, 227, 0.17) 8%,
			rgba(50, 197, 255, 0.17) 25%,
			rgba(255, 255, 255, 0.17) 50%,
			rgba(255, 255, 255, 0.17) 100%
		);
		background: linear-gradient(
			0deg,
			rgba(168, 49, 227, 0.5) 0,
			rgba(50, 197, 255, 0.5) 50%,
			rgba(255, 255, 255, 0.5) 100%
		);
	}
}

.av-settings-container {
	position: absolute;
	top: 40px;
	right: 30px;
	margin: 0;
	// left: 100%;
}

.av-settings-panel-container {
	border: none;
	background: none;
	position: absolute;
	top: -18px;
	left: -20px;
	padding: 0;
	transform: translateX(-100%);
	z-index: var(--popupZIndex);
}

.preview-mode {
	.preview-video-container {
		z-index: 100;
		&::after {
			content: '';
			width: 100%;
			height: 100%;
			background: rgba(0, 0, 0, 0.4);
			position: absolute;
			top: 0;
			left: 0;
			opacity: 0;
			transition: opacity 250ms 2000ms ease-in-out;
		}
	}

	.preview-mode-el {
		z-index: 200;
		opacity: 0;
		transition: opacity 250ms 2000ms ease-in-out;
	}

	&:hover {
		.preview-mode-el {
			transition: opacity 250ms 0s ease-in-out;
			opacity: 1;
		}

		.preview-video-container {
			&::after {
				opacity: 1;
				transition: opacity 250ms 0s ease-in-out;
			}
		}
	}
}

.return-to-record-btn {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}

.preview-video-container {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: -1;
}
