.timeline {
	&-container {
		--thumbColor: #ffffff;
		--trackColor: transparent;
		position: absolute;
		width: 100%;
		height: 100%;
		// display: flex;
		// align-items: center;
		z-index: 100;
	}

	appearance: none;
	background: transparent;
	flex-grow: 1;
	height: 100%;
	width: 100%;

	// TODO: other browsers
	&::-webkit-slider-thumb {
		appearance: none;
		background: var(--thumbColor);
		display: block;
		width: 2px;
		height: 100%;
		cursor: pointer;
	}

	&::-webkit-slider-runnable-track {
		width: 100%;
		height: 100%;
		background: linear-gradient(
			0deg,
			transparent 0,
			transparent 3px,
			var(--trackColor) 3px,
			var(--trackColor) 5px,
			transparent 5px,
			transparent 8px
		);
	}

	&:disabled {
		&::-webkit-slider-thumb {
			display: none;
		}
	}
}

.progress {
	background: var(--controlActiveColor);
	display: block;
	position: absolute;
	height: 2px;
	left: 2px;
	top: 8px;
}

.time-container {
}

.time {
	font-size: 10px;
	font-weight: 600;
	text-shadow: 0 0 1px rgba(0, 0, 0, 0.5);
	color: #ffffff;
	position: absolute;
	right: 4px;
	bottom: 4px;
}
