.dropzone {
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	opacity: 0;
	transition: opacity 250ms ease-in-out;
	// background: rgba(123, 201, 199, 0.2);
	&.drag-active {
		background: rgba(0, 0, 0, 0.8);
		display: flex;
		justify-content: center;
		align-items: center;
		color: #ffffff;
		opacity: 1;
		z-index: 1000;
		*,
		~ *,
		+ * {
			pointer-events: none;
		}
	}
}

.content {
	display: none;
	text-align: center;
	font-size: 27px;
	font-weight: 600;
	img {
		display: block;
		margin: 10px auto;
	}
	&.active {
		display: block;
	}
}
