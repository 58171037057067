@import '../../../../style/_mixins';

.lightbox {
	background: rgba(0, 0, 0, 0.9);
	width: 100%;
	height: 100%;
	position: fixed;
	top: 0;
	left: 0;
	z-index: 5000;
	font-size: 17px;
	padding: 15px;
	@include fadeInAnimation();
	display: flex;
	justify-content: center;
	align-items: center;

	&.closing {
		@include fadeOutAnimation();
	}
}

.close-icon {
	position: absolute;
	top: 15px;
	right: 10px;
	cursor: pointer;
	z-index: 100;
	border: none;
	background: none;

	path {
		fill: #000000;
	}

	@include respond-to('large') {
		top: 25px;
		right: 25px;
	}
}

.confirmation {
	&-container {
		background: #ffffff;
		border-radius: 2px;
		width: 90%;
		max-width: 656px;
		padding: 15px 10px;

		h3 {
			margin: 0;
		}

		@include respond-to('large') {
			width: 50%;
			padding: 25px;
		}
	}

	&-footer {
		display: flex;
		justify-content: flex-end;

		button {
			margin-left: 10px;
		}
	}

	&-item-name {
		font-weight: 900;
	}
}
