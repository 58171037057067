.controls-wrapper {
	--controlActiveColor: #ffffff;
	--controlInactiveColor: #676767;
	--navButtonSize: 54px;
	--actionButtonSize: 74px;
	// width: 100%;
	// padding: 10px 0;
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 24px;
}

.nav-control-button {
	width: var(--navButtonSize);
	height: var(--navButtonSize);
}

.action-control-button {
	width: var(--actionButtonSize);
	height: var(--actionButtonSize);
}

// .media-controls {
// 	padding: 0 20px;
// 	display: flex;
// 	justify-content: space-around;
// 	align-items: center;
// 	margin-bottom: 10px;
// }

// .item {
// 	height: 3px;
// 	margin: 1px;
// 	background: white;
// 	display: inline-block;
// 	position: relative;

// 	&.done {
// 		background: var(--rodeoRed);
// 	}

// 	.progress {
// 		height: 100%;
// 		display: block;
// 		position: absolute;
// 		top: 0;
// 		left: 0;
// 		background: var(--rodeoRed);
// 		transition: width 0.25s linear;
// 	}
// }

// .note {
// 	color: var(--controlInactiveColor);
// }
