@import '../../style/_mixins';

.header {
	// --avatarSize: 32px;
	position: sticky;
	left: 0;
	top: 0; // this might need to change?
	width: 100%;
	height: var(--headerHeight);
	padding: 16px 24px 8px;
	padding: 0 24px;
	z-index: 50;
	font-size: 22px;
	font-weight: 600;
	display: grid;
	justify-items: stretch;
	grid-template-columns: 1fr 1fr;
	align-items: center;
	background: #ffffff;
	z-index: 500;
	box-shadow: 0 2px 4px rgba(0, 0, 0, 0.08);

	&.recording {
		position: relative;
	}

	&.signed-in-header {
		grid-template-columns: 1fr 1fr 1fr;
	}

	h1 {
		margin: 0;
		display: flex;
	}

	nav {
		// height: var(--avatarSize);
		height: 100%;
		text-align: right;
	}

	@include contentContainer();
}

.navigation-menu {
	justify-self: center;
}

.navigation-links {
	display: flex;
	height: 100%;
	align-items: center;
	gap: 8px;
	li {
		height: 100%;
	}
}

.link {
	border-bottom: 2px solid transparent;
	padding: 0 30px;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	text-decoration: none;
	color: #606060;
	font-size: 15px;

	&:visited {
		color: #606060;
	}

	&:hover,
	&.active {
		border-color: red;
		color: #262626;
	}
}

.sign-in-button {
	border-radius: 22px;
	padding: 8px 14px;
	justify-self: end;
	border: 1px solid #363636;
	display: grid;
	grid-template-columns: 32px 1fr;
	align-items: center;

	img {
		border-radius: 50%;
		height: 24px;
		width: 24px;
	}
}
