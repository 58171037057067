.user-camera-drop-zones-wrapper {
	width: 100%;
	height: 100%;
	pointer-events: none;
	// background: rgba(192, 33, 28, 0.2);

	// Maybe put position in parent component
	position: absolute;
	top: 0;
	left: 0;
	z-index: 10;
}
