.action-area-wrapper {
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
}

.default-state-actions {
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 350px;
}

.drop-media-placeholder {
	background: url('../../../../assets/images/drop-media-placeholder.png')
		no-repeat;
	background-size: contain;
	background-position: center;
	text-align: center;
	display: flex;
	justify-content: center;
	align-items: center;
	height: 80px;
	font-size: 16px;
	font-weight: 600;

	&-faded {
		color: #bcbcbc;
	}
}

.drop-files-placeholder {
	display: flex;
	flex-direction: column;
	align-items: center;
	font-size: 16px;
	font-weight: 600;
	gap: 8px;
	color: #ffffff;
}
