@import '../../style/_mixins';

.dashboard {
	margin: 6px 0 20px;
	width: 100%;
	flex-grow: 1;
	padding: 0 24px;

	@include respond-to('medium') {
		padding: 0 calc((100% - var(--largeMinWidth)) / 2);
	}
}

.file-list {
	margin: 0;
	padding: 0;
	list-style: none;
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	gap: 20px;
	z-index: 100;

	@include respond-to('small') {
		grid-template-columns: repeat(3, 1fr);
	}

	@include respond-to('medium') {
		grid-template-columns: repeat(4, 1fr);
	}

	@include respond-to('large') {
		grid-template-columns: repeat(5, 1fr);
	}
}

// .file-item {
// 	box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.5);
// }

.placeholder {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	h1 {
		color: #262626;
		font-weight: 800;
		font-size: 27px;
	}

	&-content {
		width: 80%;
		max-width: 656px;
		padding: 55px 0;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		text-align: center;
		background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='25' ry='25' stroke='%23f9f9f9' stroke-width='8' stroke-dasharray='16%2c 16' stroke-dashoffset='7' stroke-linecap='round'/%3e%3c/svg%3e");
		border-radius: 25px;
		margin: 0 auto;

		img {
			max-width: 100%;
			max-height: 100%;
		}
	}
}

.dragging {
	.file-list,
	.file-item {
		pointer-events: none;
	}
}

.close-icon {
	cursor: pointer;
	z-index: 100;
	background: transparent;
	outline: none;
	border: none;
	padding: 0;
	height: 23px;
}

.youtube-player {
	position: fixed;
	bottom: 18px;
	right: 10px;
	border-radius: 6px;
	overflow: hidden;
	color: #ffffff;
	z-index: 500;
	display: flex;
	flex-direction: column;

	&-header {
		width: 100%;
		background: #282828;
		position: relative;
		height: 33px;
		display: flex;
		align-items: center;
		justify-content: flex-end;
		padding: 0 10px;
	}
}
