.bar {
	width: 100%;
	height: 5px;
}

.progress {
	height: 100%;
	width: 0;
	display: block;
	position: absolute;
	top: 0;
	left: 0;

	transition: width 0.25s linear;
}

.holo {
	background: #000000;

	.progress {
		background: #ff0000;
		background: linear-gradient(
			21deg,
			rgba(247, 181, 0, 1) 0%,
			rgba(182, 32, 224, 1) 50%,
			rgba(50, 197, 255, 1) 100%
		);
		background-repeat: no-repeat;
		background-size: 200%;
		background-position: 50%;
	}
}

.basic {
	background: #dddddd;
	border: 1px solid #dddddd;
	border-radius: 3px;
	height: 4px;

	.progress {
		background: #1f85ff;
		border-radius: 3px;
	}
}
