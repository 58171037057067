.react-options-list {
	&::before {
		content: '';
		width: 18px;
		height: 18px;
		background: #ffffff;
		display: block;
		position: absolute;
		bottom: -8px;
		right: 28px;
		transform: rotate(45deg);
		box-shadow: var(--boxShadow);
		border-top-right-radius: 2px;
		pointer-events: none;
	}

	&::after {
		content: '';
		width: 32px;
		height: 18px;
		background: #ffffff;
		// background: rebeccapurple;
		display: block;
		position: absolute;
		bottom: 0;
		right: 22px;
		pointer-events: none;
	}
}
