@import url(https://fonts.googleapis.com/css?family=YouTube+Sans&display=swap);
@import url(https://fonts.googleapis.com/css?family=YouTube+Sans+Dark&display=swap);
@import url(https://fonts.googleapis.com/css?family=Roboto&display=swap);

:root {
	--boxShadow: 0 2px 4px rgba(0, 0, 0, 0.5);
	--itemShadow: 0 0 6px 0 rgba(0, 0, 0, 0.3);
	--darkGrey: #474747;
	--darkestGrey: #111;
	--easing: cubic-bezier(0.76, 0, 0.24, 1);
	--lightGrey: #c9c9c9;
	--mediumGrey: #888;
	--rodeoGreen: #6dd400;
	--rodeoRed: #da3026;
	--rodeoSmallCircleSize: 60px;
	--rodeoYellow: #f7b500;
	--youTubeBlue: #3ea6ff;
	--largeMinWidth: 1440px;
	--defaultTextColor: #262626;
	--defaultBackgroundColor: #f9f9f9;
	--smallMin: 600px;
	--mediumMin: 800px;
	--largeMin: 1440px;
}

html,
body {
	height: 100%;
	width: 100%;
	padding: 0;
	margin: 0;
	font-weight: 400;
	color: var(--defaultTextColor);
	background: var(--defaultBackgroundColor);
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

* {
	box-sizing: border-box;
	position: relative;
	font-family: system-ui, BlinkMacSystemFont, sans-serif;
	font-weight: 400;
}

#preact_root {
	height: 100%;
}

button {
	background: none;
	outline: none;
	border: none;
	cursor: pointer;
}

ul {
	margin: 0;
	padding: 0;
	list-style: none;
}
