.layout {
	&-grid {
		--anchorTop: 0px;
		--anchorLeft: 0px;
		--optionPadding: 8px;
		--borderWidth: 3px;
		--gapSize: 8px;
		// height: calc(100vh - 40px);
		display: grid;
		grid-template-columns: 1fr 1fr;
		grid-template-rows: 1fr 1fr;
		gap: var(--gapSize);
		padding: var(--gapSize);
		background: #262626;
		// background: pink;
		position: absolute;
		z-index: 100000;
		// top: calc(var(--anchorTop) - var(--optionPadding) - var(--borderWidth));
		// top: calc(var(--anchorTop) + var(--gapSize));
		// left: calc(var(--anchorLeft) + var(--gapSize));
		// top: 0;
		// left: 0;
		// width: calc(200% + (var(--gapSize) * 3));
		// height: calc(200% + (var(--gapSize) * 3));
		// height: 100%;
		animation: zoomOut 400ms cubic-bezier(0.24, 0, 0.5, 1) both;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		z-index: -1;

		&.zoom-in {
			animation: zoomIn 400ms cubic-bezier(0.24, 0, 0.5, 1) both;
			// width: calc(200% + (var(--optionPadding) * 2));
			// height: calc(200% + (var(--optionPadding) * 2));
			// top: calc(var(--anchorTop) - var(--gapSize));
			// left: calc(var(--anchorLeft) - var(--gapSize));
		}
		&.active {
			z-index: 2000;
		}
	}

	&-option {
		// padding: var(--optionPadding);
		cursor: pointer;
		overflow: hidden;
		border-radius: 8px;

		&::before {
			content: '';
			width: 100%;
			height: 100%;
			background: rgba(0, 0, 0, 0.5);
			position: absolute;
			top: 0;
			left: 0;
			z-index: 100;
			transition: background 250ms ease-in-out;
		}

		&:hover {
			&::before {
				background: rgba(0, 0, 0, 0);
			}
		}

		&.active-layout {
			&::after {
				content: 'Active';
				width: 100%;
				height: 100%;
				position: absolute;
				top: 0;
				left: 0;
				color: #ffffff;
				z-index: 100;
				display: flex;
				justify-content: center;
				align-items: center;
				box-sizing: border-box;
				border-radius: 8px;
				border: 2px solid #ffffff;
			}
		}

		canvas {
			border-radius: 8px;
			// border: var(--borderWidth) inset transparent;
			box-sizing: border-box;
		}
	}
}

@keyframes zoomIn {
	0% {
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
	}

	100% {
		width: 200%;
		height: 200%;
		top: var(--anchorTop);
		left: var(--anchorLeft);
	}
}

@keyframes zoomOut {
	0% {
		width: 200%;
		height: 200%;
		top: var(--anchorTop);
		left: var(--anchorLeft);
	}

	100% {
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
	}
}
