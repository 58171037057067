@import '../../../../style/_mixins';

.action-menu {
	margin: 0;
	padding: 0;
	list-style: none;

	&-container {
		position: fixed;
		bottom: 0;
		left: 0;
		z-index: 3000;
		width: 100%;
		padding: 16px;
		background: #343434;
		color: #ffffff;
		// box-shadow: 0 -4px 8px rgba(0, 0, 0, 0.6);
		border-top-right-radius: 11px;
		border-top-left-radius: 11px;
	}

	&-item {
		margin: 10px 0;
		font-size: 16px;
		font-weight: 600;
	}

	&-header {
		display: flex;
		justify-content: flex-end;
	}
}

.action-stream {
	padding-left: 10px;
	height: 45px;
	line-height: 45px;
	display: flex;
	align-items: center;
	gap: 5px;
}

.item-details {
	display: flex;
	align-items: center;
	gap: 10px;
	flex-direction: column;
}

.item-title {
	font-weight: 900;
	overflow: hidden;
	text-overflow: ellipsis;
	max-width: 100%;
	white-space: nowrap;
}

.item-thumb {
	max-height: 80px;
	max-width: calc(100% - 50px);
}

.close-icon {
	cursor: pointer;
	z-index: 100;
	background: transparent;
	outline: none;
	border: none;
	padding: 0;
	height: 23px;
}
