.footer {
	z-index: 100;
	position: fixed;
	bottom: 36px;
	left: 50%;
	transform: translateX(-50%);
	text-align: center;
	justify-content: center;
	align-items: center;
	display: flex;
}
