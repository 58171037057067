.playlist-container {
	width: calc(100% - 120px);
	justify-self: center;

	&::before,
	&::after {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		width: 20px;
		height: 100%;
		background: linear-gradient(
			-90deg,
			transparent 0,
			var(--defaultBackgroundColor) 20px
		);
		z-index: 100;
		pointer-events: none;
	}

	&::after {
		left: calc(100% - 20px);
		transform: rotate(180deg);
	}
}

.playlist-wrapper,
.playlist-items {
	display: flex;
	gap: 6px;
}
.playlist-wrapper {
	width: 100%;
	height: 70px;
	// max-width: 100%;
	// background: dodgerblue;
	overflow-x: auto;
	align-items: start;
	justify-content: start;
	justify-self: center;
	// transition: padding-left 400ms cubic-bezier(0.24, 0, 0.5, 1);
	// padding-left: calc(50% - 25px);
}

.playlist-items {
	margin-left: calc(50% + 25px);
	transition: margin-left 400ms cubic-bezier(0.24, 0, 0.5, 1);

	// background: rgba(0, 0, 0, 0.25);
	min-width: 50px;
}
