.progress-pill {
	height: 60px;
	width: 248px;
	border-radius: 30px;
	border: 1px solid #ffffff;
	color: #262626;
	background: #ffffff;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: 4px;
	font-size: 12px;

	&-status-text {
		font-weight: 500;
	}

	&.done {
		flex-direction: row;
		gap: 8px;
	}
}

.progress-bar-container {
	width: 160px;
}
