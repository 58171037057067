.device-list-wrapper {
	--optionHeight: 32px;
	display: grid;
	grid-template-columns: 34px 213px;
	grid-template-rows: var(--optionHeight);
	align-items: center;

	svg {
		justify-self: start;
	}
}

.device-option-list {
	--arrowSize: 4px;
	align-self: start;
	background: #f2f2f2;
	border: 1px solid #efefef;
	border-radius: 16px;
	height: var(--optionHeight);
	display: flex;
	flex-direction: column;
	flex-grow: 1;
	overflow: hidden;

	&::after {
		content: '';
		border-top: var(--arrowSize) solid #262626;
		border-left: var(--arrowSize) solid transparent;
		border-right: var(--arrowSize) solid transparent;
		width: 0;
		height: 0;
		// background: #262626;
		display: block;
		position: absolute;
		right: 15px;
		top: calc(var(--optionHeight) / 2 - var(--arrowSize) / 2);
		transform: translateY(-50%);
	}

	&.active {
		box-shadow: var(--boxShadow);
		height: auto;
		z-index: 100;

		&::after {
			border-top: none;
			border-bottom: var(--arrowSize) solid #262626;
		}

		// .device-option.selected {
		// 	order: 2;
		// }
	}

	&.cant-open {
		pointer-events: none;

		&::after {
			content: none;
		}

		.device-option {
			padding: 0 15px;
		}
		// background: #f4f4f4;
	}
}

.device-option {
	align-items: center;
	cursor: pointer;
	display: flex;
	padding: 0 30px 0 15px;
	height: var(--optionHeight);
	min-height: var(--optionHeight);
	order: 2;
	position: relative;
	white-space: nowrap;
	order: 1;
	font-size: 12px;

	&:not(.selected) {
		order: 1;
	}

	&.selected {
		order: 0;
		display: grid;
		grid-template-columns: 1fr 25px;
		align-items: center;
		gap: 8px;
	}

	&:hover:not(.selected) {
		background: #f4f4f4;
	}
}

.device-label {
	overflow: hidden;
	text-overflow: ellipsis;
}
