@import '../../../../style/_mixins';

// TODO: this could be cleaned up and simplified

.item {
	aspect-ratio: 1;
	overflow: hidden;
	border-radius: 11px;
	position: relative;
	box-shadow: var(--itemShadow);

	&-thumb,
	video {
		object-fit: cover;
		object-position: 50% 50%;
		width: 100%;
		height: 100%;
		transition: opacity 250ms ease-in-out;
		border-radius: 11px;
	}

	&-duration {
		position: absolute;
		bottom: 10px;
		right: 10px;
		color: #ffffff;
		font-size: 16px;
		font-weight: 600;
		text-shadow: 0 1px 2px rgb(0 0 0 / 60%), 0 0 2px rgb(0 0 0 / 30%);
	}

	.status {
		z-index: 100;
		backdrop-filter: blur(30px) contrast(1.5);
		width: 100%;
		height: 100%;
		display: block;
		border-radius: 11px;
	}

	&:hover {
		.detail-element {
			display: block;
		}
	}
}

.temp {
	position: relative;
	background: #000000;
	color: #ffffff;

	video {
		pointer-events: none;
		position: absolute;
		top: 0;
		left: 0;
		border-radius: 11px;
	}
}

.detail {
	// &-element {
	// 	display: none;
	// }

	// &-overlay {
	// 	background: rgba(0, 0, 0, 0.75);
	// 	width: 100%;
	// 	position: absolute;
	// 	bottom: 0;
	// 	left: 0;
	// 	color: #ffffff;
	// 	padding: 10px 25px;
	// }

	&-action {
		font-size: 20px;
		font-weight: 500;
		display: block;
	}

	&-prompt {
		font-size: 12px;
		display: block;
	}
}

.uploaded-icon {
	position: absolute;
	top: 8px;
	left: 5px;
}

.action-menu {
	background: #ffffff;
	margin: 0;
	padding: 0;
	display: none;
	list-style: none;
	position: absolute;
	top: 12px;
	border: 1px solid #ededed;
	box-shadow: 0 2px 5px rgba(0, 0, 0, 0.5);
	z-index: 200;
	cursor: text;
	max-width: 300px;

	&:hover,
	&.active {
		display: block;
	}

	li {
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;

		&:hover {
			background: #f5f5f5;
		}
	}

	&-container {
		position: absolute;
		top: 8px;
		right: 12px;

		&:hover {
			.action-menu {
				display: block;
			}
		}
	}

	&-text {
		position: absolute;
		left: 2000%;
	}

	&-icon {
		cursor: pointer;
	}

	button {
		padding: 10px 8px;
		width: 100%;
	}

	.item-title {
		padding: 10px 8px;
		font-weight: 900;
		border-bottom: 1px solid #eaeaea;
		pointer-events: none;
		cursor: text;
	}

	&-dot {
		width: 6px;
		height: 6px;
		border-radius: 50%;
		background: #ffffff;
		box-shadow: 0 0 4px rgba(0, 0, 0, 0.3);
		display: block;
		margin: 3px 0;
	}

	@include respond-to('medium') {
		padding-bottom: 6px;

		.item-title {
			margin-bottom: 6px;
		}
	}

	@include respond-to('large') {
		.item-title,
		button {
			padding: 10px 25px;
		}
	}
}

.cloud-icon {
	position: absolute;
	top: 8px;
	left: 5px;
}

.item-actions {
	position: absolute;
	width: 100%;
	height: 100%;
	z-index: 100;
	opacity: 0;
	transition: opacity 125ms ease-in-out;
	background: rgba(0, 0, 0, 0.5);
	cursor: pointer;
	border-radius: 11px;

	&:hover,
	&.active {
		opacity: 1;
	}

	&.active {
		.action-menu {
			display: block;
		}
	}
}

.live-replay-link {
	font-weight: 600;
	padding: 8px 10px;
}

.item-actions-cta {
	background: rgba(0, 0, 0, 0.7);
	padding: 15px 35px;
	border-radius: 30px;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	white-space: nowrap;
	color: #ffffff;
	font-family: 'YouTube Sans Dark';
	cursor: pointer;

	.item-duration {
		position: static;
	}
}

.mobile-actions {
	position: absolute;
	z-index: 100;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}
