.preview-video {
	// position: absolute;
	// top: 0;
	// left: 0;
	// z-index: 100;
	// // width: 300px;
	width: 100%;
	height: 100%;

	// position: fixed;
	// width: 300px;
	// z-index: 100000;
}
