.add-content {
	&-wrapper {
		&::before {
			content: '';
			width: 18px;
			height: 18px;
			background: #ffffff;
			display: block;
			position: absolute;
			bottom: -8px;
			left: 54px;
			transform: rotate(45deg);
			box-shadow: var(--boxShadow);
			border-top-right-radius: 2px;
			pointer-events: none;
		}

		&::after {
			content: '';
			width: 32px;
			height: 18px;
			background: #ffffff;
			// background: rebeccapurple;
			display: block;
			position: absolute;
			bottom: 0;
			left: 46px;
			pointer-events: none;
		}
	}
	&-list {
		display: flex;
		flex-direction: column;
		gap: 6px;
	}

	&-option {
		height: 60px;
		box-shadow: 0 0 5px rgba(0, 0, 0, 0.11);
		border-radius: 8px;
		padding: 0 20px;
		display: flex;
		align-items: center;
	}
}
