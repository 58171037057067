.progress {
	display: flex;
	justify-content: center;
	flex-direction: column;
	align-items: center;
	height: 100%;
	font-weight: 600;
	text-shadow: 0 0 2px #000000;

	&-track {
		background: #343434;
		border: 1px solid #343434;
		height: 8px;
		border-radius: 4px;
		position: relative;
		width: 78%;
		overflow: hidden;
		margin: 10px 0 8px;
	}

	&-bar {
		background: #ffffff;
		height: 100%;
		position: absolute;
		left: 0;
		top: 0;
	}

	&-text {
		// width: 100px;
		// padding-left: 10px;
		font-size: 18px;
	}

	&-time {
		font-size: 18px;
	}

	&.with-bar {
		.progress-text {
			font-size: 14px;
		}
	}
}

.cloud {
	position: absolute;
	top: 10px;
	left: 10px;
	width: 33px; // width of cloud svg
	height: 23px; // height of cloud svg

	&-background {
		position: absolute;
		top: 0;
		left: 0;
	}

	&-progress-fill {
		position: absolute;
		top: 5px;
		left: 5px;
		clip-path: url(#cloudPath);
		-webkit-clip-path: url(#cloudPath);
		background: #f00;
		background: linear-gradient(
			135deg,
			rgba(255, 255, 255, 0.58) 0,
			rgba(255, 255, 255, 0.58) 6.25%,
			transparent 6.25%,
			transparent 12.5%,
			rgba(255, 255, 255, 0.58) 12.5%,
			rgba(255, 255, 255, 0.58) 18.75%,
			transparent 18.75%,
			transparent 25%,
			rgba(255, 255, 255, 0.58) 25%,
			rgba(255, 255, 255, 0.58) 31.25%,
			transparent 31.25%,
			transparent 37.5%,
			rgba(255, 255, 255, 0.58) 37.5%,
			rgba(255, 255, 255, 0.58) 43.75%,
			transparent 43.75%,
			transparent 50%,
			rgba(255, 255, 255, 0.58) 50%,
			rgba(255, 255, 255, 0.58) 56.25%,
			transparent 56.25%,
			transparent 62.5%,
			rgba(255, 255, 255, 0.58) 62.5%,
			rgba(255, 255, 255, 0.58) 68.75%,
			transparent 68.75%,
			transparent 75%,
			rgba(255, 255, 255, 0.58) 75%,
			rgba(255, 255, 255, 0.58) 81.25%,
			transparent 81.25%,
			transparent 87.5%,
			rgba(255, 255, 255, 0.58) 87.5%,
			rgba(255, 255, 255, 0.58) 93.75%,
			transparent 93.75%
		);
		height: 100%;
		width: 100%;
		display: block;
		background-size: 200% 200%;
		animation: side 1s linear infinite;
		background-position: 85% 0%;
	}

	svg {
		filter: drop-shadow(0 0 4px #000000);
	}
}

.cancel-upload-btn {
	position: absolute;
	top: 12px;
	right: 12px;
	background: none;
	outline: none;
	border: none;
	cursor: pointer;
}

@keyframes side {
	0% {
		background-position: 85% 0%;
	}

	100% {
		background-position: 0% 0%;
	}
}

@keyframes ellipsis {
	0% {
		content: '\00A0';
	}
	25% {
		content: '\00A0.';
	}
	50% {
		content: '\00A0. .';
	}
	75% {
		content: '\00A0. . .';
	}
	0% {
		content: '\00A0';
	}
}
