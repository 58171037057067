@import '../../../../style/_mixins';

.item {
	width: auto;
	height: auto;
	max-width: 100%;
	max-height: 100%;
	border-radius: 11px;
	overflow: hidden;
	aspect-ratio: 1;
}

.image-container {
	width: auto;
	height: auto;
	max-width: 100%;
	max-height: 100%;

	img {
		width: 100%;
		height: 100%;
		object-fit: cover;
		aspect-ratio: 1;
	}
}

.action-menu {
	background: #ffffff;
	margin: 0;
	padding: 0;
	display: none;
	list-style: none;
	position: absolute;
	top: 29px;
	border: 1px solid #ededed;
	box-shadow: 0 2px 5px rgba(0, 0, 0, 0.5);
	z-index: 200;
	cursor: text;
	max-width: 300px;
	z-index: 300;

	&:hover,
	&.active {
		display: block;
	}

	li {
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;

		&:hover {
			background: #f5f5f5;
		}
	}

	&-container {
		position: absolute;
		top: 8px;
		right: 12px;

		&:hover {
			.action-menu {
				display: block;
			}
		}
	}

	&-text {
		position: absolute;
		left: 2000%;
	}

	&-icon {
		cursor: pointer;
	}

	button {
		padding: 10px 8px;
		width: 100%;
	}

	.item-title {
		color: #000000;
		padding: 10px 8px;
		font-weight: 900;
		border-bottom: 1px solid #eaeaea;
		pointer-events: none;
		cursor: text;
	}

	&-dot {
		width: 6px;
		height: 6px;
		border-radius: 50%;
		background: #ffffff;
		box-shadow: 0 0 4px rgba(0, 0, 0, 0.3);
		display: block;
		margin: 3px 0;
	}

	@include respond-to('medium') {
		padding-bottom: 6px;

		.item-title {
			margin-bottom: 6px;
		}
	}

	@include respond-to('large') {
		.item-title,
		button {
			padding: 10px 25px;
		}
	}
}

.item-actions {
	top: 0;
	left: 0;
	position: absolute;
	width: 100%;
	height: 100%;
	z-index: 100;
	opacity: 0;
	transition: opacity 125ms ease-in-out;
	background: rgba(0, 0, 0, 0.5);
	cursor: pointer;
	border-radius: 11px;

	&:hover {
		opacity: 1;
	}
}

.mobile-actions {
	position: absolute;
	z-index: 100;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

.item-title {
	white-space: nowrap;
	text-overflow: ellipsis;
	width: 80%;
	overflow: hidden;
}
.item-buttons {
	display: flex;
	gap: 5px;
	justify-content: space-around;
}

.uploaded-icon {
	position: absolute;
	top: 5px;
	left: 5px;
}
