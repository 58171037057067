.user-menu {
	display: none;
	background: #ffffff;
	// font-family: 'Roboto', serif;
	box-shadow: 0 2px 4px rgba(0, 0, 0, 0.14);
	width: 300px;
	position: absolute;
	right: -14px;

	&-container {
		justify-self: end;
	}

	&-toggle {
		cursor: pointer;
	}
	&-open {
		display: block;
	}

	&-item {
		border-top: 1px solid #e5e5e5;
		font-size: 13px;
		text-align: left;

		&:first-of-type {
			border-top: 1px solid transparent;
		}

		&-content {
			padding: 20px;
			display: flex;
			align-items: center;
			width: 100%;
			height: 100%;
		}

		&-icon {
			margin-right: 20px;
		}
	}
}

.avatar {
	border-radius: 50%;
	height: 32px;
	width: 32px;
}
