.option {
	display: flex;
	flex-direction: column;
	font-size: 12px;

	&-title {
		font-weight: 600;
		margin: 16px 0 2px;
	}
	&-body {
		display: flex;
		justify-content: space-between;
		align-items: center;
		gap: 50px;
	}

	&-description {
		max-width: 182px;
		margin: 0;
	}
}
