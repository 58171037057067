.modal-wrapper {
	z-index: 1000000;
	display: flex;
	justify-content: center;
	align-items: center;
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

.modal-content {
	border-radius: 8px;
	background: #ffffff;
	padding: 26px 50px;
}

.takeover {
	position: fixed;
}

.overlay {
	background: rgba(0, 0, 0, 0.5);
}

.close-button {
	position: absolute;
	top: 15px;
	right: 15px;
}

.close-icon {
	font-size: 32px;
	line-height: 16px;
}
