.video-state {
	&-wrapper {
		position: absolute;
		top: 0;
		left: 50%;
		transform: translateX(-50%);
		height: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
		pointer-events: none;
		opacity: 1;
		animation: fadeOut 400ms 1000ms var(--easing) both;

		path {
			fill: #ffffff;
		}
	}
}

@keyframes fadeOut {
	0% {
		opacity: 1;
	}

	100% {
		opacity: 0;
	}
}
