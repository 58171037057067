.user-camera-drop-zone {
	position: absolute;

	// &.pos-circle-pos {
	// }
	// 	&.circle-shape {
	// 		width: 180px; // TODO: How do I figure out the size?
	// 		height: 180px;
	// 		border-radius: 50%;
	// 	}

	// &.top,
	// &.bottom {
	// }

	// &.left,
	// &.right {
	// }
}

.layout {
	&-top,
	&-bottom {
		width: 100%;
		height: 50%;
	}

	&-left,
	&-right {
		width: 50%;
		height: 100%;
	}

	&-circle {
		&.shape {
			&-circle {
				border-radius: 50%;
			}

			&-landscape {
				width: 87.9%;
				aspect-ratio: 16/9;
				border-radius: 12px;
			}

			&-portraitish {
				aspect-ratio: 9/16;
				border-radius: 12px;
			}

			&-landscapeish {
				aspect-ratio: 16/9;
				border-radius: 12px;
			}
		}
	}
}

.show-zone {
	border: 2px dashed #ffffff;
	background: rgba(0, 0, 0, 0.5);
	opacity: 0.2;
	transition: opacity 250ms ease-in-out;
	&.active {
		opacity: 1;
	}
}
