.filmstrip-wrapper {
	width: 100%;
	height: 64px;
	background: #000000;
	border: 1px solid #000000;
	border-radius: 6px;
	padding: 7px;
	display: grid;
	grid-template-columns: 42px 1fr 42px;
	align-items: center;
	color: #ffffff;
	cursor: pointer;

	&:hover {
		border-color: #ffffff;
	}
}

.export-button {
	height: 100%;
	width: 100%;
	gap: 4px;

	&-container {
		height: 42px;
		border-radius: 3px;
		display: flex;
		justify-content: center;
		align-items: center;
		overflow: hidden;

		&:hover {
			background: #ffffff;
		}
	}
}

// timeline
.timeline {
	&-container {
		--thumbColor: #ffffff;
		--trackColor: #393939;
		width: 100%;
		height: 100%;
		display: flex;
		justify-content: center;
		// align-items: center;
		z-index: 100;
	}

	appearance: none;
	background: transparent;
	// flex-grow: 1;
	// height: 100%;
	width: 80%;

	// TODO: other browsers
	&::-webkit-slider-thumb {
		appearance: none;
		background: var(--thumbColor);
		display: block;
		width: 12px;
		height: 12px;
		border: 1px solid #000000;
		border-radius: 50%;
		cursor: pointer;
		transform: translateY(-4px);
	}

	&::-webkit-slider-runnable-track {
		width: 100%;
		height: 4px;
		border-radius: 8px;
		// height: 100%;
		// background: linear-gradient(
		// 	0deg,
		// 	transparent 0,
		// 	transparent 3px,
		// 	var(--trackColor) 3px,
		// 	var(--trackColor) 5px,
		// 	transparent 5px,
		// 	transparent 8px
		// );
		background: var(--trackColor);
	}

	&:disabled {
		&::-webkit-slider-thumb {
			display: none;
		}
	}
}

.progress {
	background: var(--controlActiveColor);
	display: block;
	position: absolute;
	height: 2px;
	left: 2px;
	top: 8px;
}

.time-container {
}

.time {
	font-size: 10px;
	font-weight: 600;
	text-shadow: 0 0 1px rgba(0, 0, 0, 0.5);
	color: #ffffff;
	position: absolute;
	right: 4px;
	bottom: 4px;
}
