.controls-container {
	display: grid;
	grid-template-columns: 1fr 1fr 1fr;
	justify-items: stretch;
	align-items: end;
	align-self: flex-end;
	padding: 0 24px 24px;
	height: 100%;

	&.default-state {
		justify-items: end;
		// grid-template-columns: 1fr 1fr 1fr;
	}

	&.adding-files-from-computer {
		grid-template-columns: 1fr;
	}

	&.disabled {
		opacity: 0.03;
		pointer-events: none;
	}

	// background: linear-gradient(
	// 		90deg,
	// 		transparent 0,
	// 		transparent calc(50% - 1px),
	// 		dodgerblue calc(50% - 1px),
	// 		dodgerblue calc(50% + 1px),
	// 		transparent calc(50% + 1px)
	// 	),
	// background: linear-gradient(
	// 	0deg,
	// 	transparent 0,
	// 	transparent calc(50% - 1px),
	// 	dodgerblue calc(50% - 1px),
	// 	dodgerblue calc(50% + 1px),
	// 	transparent calc(50% + 1px)
	// );
}

.footer-left {
	justify-self: start;
	display: flex;
	align-items: baseline;
	justify-content: space-between;
	width: 100%;
}

.shortcuts-container {
	justify-self: start;
	margin-right: 20px;
	// left: 0;
}

.center-controls-container {
	justify-self: center;
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 24px;
}

.add-content-panel-container {
	position: absolute;
	bottom: calc(100% + 20px);
	transform: translateX(-100%);
	z-index: var(--popupZIndex);
}

.react-options-panel-container {
	position: absolute;
	bottom: calc(100% + 20px);
	transform: translateX(-100%);
	z-index: var(--popupZIndex);
}

.av-settings-panel-container {
	position: absolute;
	left: 68px;
	bottom: calc(100% - 40px);
}
