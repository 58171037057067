.add-media-button {
	cursor: pointer;
	overflow: hidden;
	display: flex;
	justify-content: space-between;
	height: 40px;

	input {
		display: none;
	}

	&.inline {
		justify-content: center;

		.label-text {
			flex-direction: row;
		}
	}
}

.label-text {
	font-weight: 500;
	font-size: 9px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	white-space: nowrap;
	gap: 8px;
}
