.item {
	&-preview {
		height: 50px;
		position: relative;
		cursor: pointer;
		border-radius: 4px;
		overflow: hidden;
		box-shadow: 0 0 4px rgba(0, 0, 0, 0.5);

		display: block;
		width: 100%;

		&.active {
			border: 2px solid #c81111;
		}

		&.disabled {
			pointer-events: none;
			opacity: 0.03;

			.item-video.selected {
				width: 50px;
			}
		}

		&.dragging {
			// opacity: 0;
			// position: relative;
			// z-index: -1;
			// position: absolute;
			// width: 1px;
			// border: 3px solid red;
			// display: none;
		}

		video,
		img {
			width: 100%;
			height: 100%;
			max-height: 100px;
			max-width: 100px;
			object-fit: cover;
			// position: static;
		}
	}

	&-image,
	&-screen {
		width: 50px;
	}

	&-video {
		width: 50px; // for now

		&.selected {
			width: max-content;
			min-width: 50px;
			max-width: 400px;
			display: flex;

			// background: grey; // temp
		}
	}

	&:hover {
		.remove {
			display: flex;
		}
	}
}

.focussed {
	animation-name: wiggle;
	animation-iteration-count: infinite;
	animation-duration: 350ms;
	transform-origin: 50% 10%;

	.item-meta {
		display: none;
	}

	.play-item-wrapper {
		display: flex;
	}
}

.item-meta {
	font-size: 10px;
	font-weight: 600;
	text-shadow: 0 0 1px rgba(0, 0, 0, 0.5);
	color: #ffffff;
	position: absolute;
	right: 4px;
	bottom: 4px;

	// display: none;
}

.play-item-wrapper {
	display: none;
	width: 100%;
	height: 100%;
	background: rgba(0, 0, 0, 0.7);
	position: absolute;
	top: 0;
	left: 0;
	color: #ffffff;
	justify-content: center;
	align-items: center;
	flex-direction: column;

	path {
		fill: #ffffff;
	}

	.label-text {
		font-size: 9px;
	}
}

.video-thumbs {
	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(50px, 1fr));
	grid-auto-flow: column;
	width: 100%;
}

.ghost-item {
	// background: red;
	height: 50px;
	position: relative;
	// position: absolute;
	// top: 0;
	// left: 0;
	// z-index: 10000;
	// pointer-events: none;
	border-radius: 4px;
	overflow: hidden;
	box-shadow: 0 0 18px rgba(0, 0, 0, 0.5);

	display: block;
	// width: 100%;
	width: 50px;
	// z-index: -1;
	video,
	img {
		width: 100%;
		height: 100%;
		max-height: 100px;
		max-width: 100px;
		object-fit: cover;
		// position: static;
	}
}

@keyframes wiggle {
	0% {
		transform: rotate(-1deg);
		animation-timing-function: ease-in;
	}

	50% {
		transform: rotate(1.5deg);
		animation-timing-function: ease-out;
	}
}
