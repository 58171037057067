.input-item {
	border: 1px solid #979797;
	border-radius: 5px;
	padding: 10px 12px;
	margin-bottom: 22px;

	label {
		font-size: 12px;
		display: block;
	}

	.input-field {
		margin-top: 3px;
		width: 100%;
		background: none;
		border: none;
		outline: none;
		line-height: 20px;
		color: #ffffff;
		font-size: 16px;
		font-family: 'YouTube Sans Dark';

		&:disabled {
			color: #aaaaaa;
		}

		&::placeholder {
			color: #aaaaaa;
		}

		&.with-char-count:not(textarea) {
			width: calc(100% - 50px);
		}
	}

	textarea {
		resize: none;
	}

	select {
		background: none;
		display: block;
		border: none;
		outline: none;
		margin-top: 10px;
		color: #ffffff;
		width: 100%;
	}

	.with-icon {
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin-top: 10px;

		select {
			margin-top: 0;
			width: auto;
			flex-grow: 1;
		}

		svg {
			margin-right: 10px;
		}
	}
}

.input-character-count {
	position: absolute;
	bottom: 0;
	right: 0;
	font-size: 12px;

	&-close {
		color: yellow;
	}

	&-over {
		color: red;
	}
}
