.delete-confirmation {
	&-modal {
		max-width: 330px;
	}

	&-content {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		text-align: center;
	}
	&-title {
		font-size: 18px;
		font-weight: 600;
	}
}
