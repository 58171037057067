.audio-meter {
	display: grid;
	grid-template-columns: 3px 3px 3px 3px 3px;
	grid-template-rows: 3px;
	gap: 2px;

	&-container {
		display: flex;
		gap: 5px;
		align-items: center;
	}

	&-dot {
		background: #ededed;
		display: block;

		&::before {
			width: 100%;
			height: 100%;
			display: block;
			border-radius: 50%;
		}

		&-full {
			&::before {
				content: '';
			}
		}

		&-safe {
			&::before {
				background: #00c30d;
			}
		}

		&-warn {
			&::before {
				background: #fbbf30;
			}
		}

		&-high {
			&::before {
				background: #c40000;
			}
		}
	}
}
