.rodeo {
	--headerHeight: 56px;
	height: 100vh;
	transition: top 0.25s ease-in-out;

	&.logged-in {
		display: grid;
		grid-template-rows: var(--headerHeight) 1fr;
	}

	&.recording {
		height: calc(100vh + var(--headerHeight));
		top: calc(var(--headerHeight) * -1);
	}

	&-content {
		height: 100%;
		> * {
			height: 100%;
		}
	}
}

.centering {
	position: fixed;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
	background: linear-gradient(
		90deg,
		transparent 0,
		transparent calc(50% - 1px),
		dodgerblue calc(50% - 1px),
		dodgerblue calc(50% + 1px),
		transparent calc(50% + 1px)
	);
	z-index: 200000;
}
