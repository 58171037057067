.canvas {
	width: 100%;
	height: 100%;

	&.portrait {
		aspect-ratio: 9/16;
	}

	&.landscape {
		aspect-ratio: 16/9;
	}
}
