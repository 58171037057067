.delete-drop-zone {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	font-size: 9px;
	color: #cccccc;
}

.icon {
	width: 67px;
	height: 67px;
	border-radius: 50%;
	display: flex;
	justify-content: center;
	align-items: center;

	&.over {
		background: #262626;
		color: #ffffff;

		.active-icon {
			display: block;
		}

		.inactive-icon {
			display: none;
		}
	}
}

.active-icon {
	display: none;
}

.delete-text {
	display: block;
	width: 100%;
	height: 14px;
}
