.permissions-overlay {
	background: rgba(0, 0, 0, 0.8);
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	gap: 24px;
	text-align: center;
	color: #efefef;
	padding: 40px 20px;
	font-size: 24px;
	font-weight: 900;
}

.permissions {
	display: flex;
	justify-content: space-between;
	align-items: center;
	gap: 50px;
}
