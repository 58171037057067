@import '../../../../style/_mixins';

// this CSS has gotten out of hand. Refactor?

.lightbox {
	--borderColor: #222222;
	background: #000000;
	width: 100%;
	height: 100%;
	position: fixed;
	top: 0;
	left: 0;
	z-index: 10000;
	color: #ffffff;
	font-size: 17px;
	padding: 15px;
	@include fadeInAnimation();
	display: flex;
	justify-content: center;
	align-items: center;

	&.closing {
		@include fadeOutAnimation();
	}

	&-content {
		// width: clamp(330px, calc(min-content + 30px), 950px);
		max-width: 100%;
		max-height: 90%;
		width: fit-content;
		width: clamp(300px, 700px, 100%);
		height: fit-content;
		box-shadow: 0 2px 6px rgba(0, 0, 0, 0.5);
		background: #282828;
		border-radius: 6px;
		font-family: 'YouTube Sans Dark';
		overflow-y: auto;

		&.video-selector {
			width: 65%;
		}

		&.stream-summary {
			width: 65%;
		}

		&-header {
			display: flex;
			justify-content: space-between;
			align-items: center;
			border-bottom: 1px solid var(--borderColor);
			padding: 18px 25px 18px 18px;
		}

		&-title {
			color: #ffffff;
			font-size: 20px;
			font-weight: 600;
			margin: 0;
			display: flex;
			align-items: center;
			gap: 5px;

			svg {
				transform: scale(0.75);
			}
		}

		&-footer {
			border-top: 1px solid var(--borderColor);
			padding: 18px 25px 18px 18px;
			display: flex;
			flex-direction: column-reverse;
			justify-content: space-between;
			gap: 20px;
		}

		&-body {
			padding: 20px 10px;
		}

		&-text {
			font-size: 16px;
		}

		&-heading {
			font-size: 24px;
			font-weight: 600;
		}

		@include respond-to('medium') {
			min-width: 500px;

			&-body {
				padding: 20px 30px;
			}

			&-footer {
				flex-direction: row-reverse;
			}
		}
	}

	// Inception lightbox
	// TODO: lightbox component with a dark and light version
	&-modal {
		background: rgba(0, 0, 0, 0.6);
		color: #000000;

		&-header {
			border-bottom: none;
			padding: 0;

			path {
				fill: #202020;
			}
		}

		&-title {
			color: #262626;
			font-weight: 600;
			font-size: 24px;
		}

		&-content {
			background: #ffffff;
			width: clamp(300px, 650px, 90%);
		}

		&-footer {
			border-top: none;
			padding: 18px 0 0;
			justify-content: flex-start;
		}
	}
}

.video-selection-content {
	height: 100%;
}

.video-selection-list {
	height: calc(100% - 213px);

	@include respond-to('medium') {
		height: calc(100% - 142px);
	}
}

.close-icon {
	cursor: pointer;
	z-index: 100;
	background: transparent;
	outline: none;
	border: none;
	padding: 0;
	height: 23px;
}

.broadcast-details-entry {
	display: flex;
	flex-direction: column;

	&-date {
		grid-area: date;
		order: 2;
	}

	&-title {
		grid-area: title;
		order: 2;
	}

	&-description {
		grid-area: description;
		order: 2;

		label {
			padding-bottom: 10px;
		}
	}

	&-thumbnail {
		grid-area: thumbnail;
		order: 2;
	}

	&-video-details {
		grid-area: video;
		order: 1;
		margin-bottom: 20px;
		width: 100%;

		&-video {
			display: flex;
			justify-content: center;
			align-items: center;

			button {
				margin: 20px;
			}
		}

		&-preview {
			display: flex;
			flex-direction: column;
			width: 100%;
		}

		&-thumb-container {
			background: #000000;
			width: 100%;
			aspect-ratio: 16 / 9;
			max-height: 200px;
			height: min-content;
			display: flex;
			justify-content: center;
			align-items: center;

			video {
				max-height: 100%;
				max-width: 100%;
			}
		}

		&-info {
			background: #1f1f1f;
			border-radius: 6px;
			border-top-left-radius: 0;
			border-top-right-radius: 0;
			padding: 20px 15px;
		}

		&-label {
			font-size: 12px;
		}

		&-name {
			display: block;
			font-size: 16px;
			text-overflow: ellipsis;
			overflow: hidden;
			max-width: 250px;
		}
	}

	.hidden-file-input {
		cursor: pointer;
		color: var(--youTubeBlue);
		font-weight: 600;
		font-size: 14px;

		&:hover {
			color: #ffffff;
		}

		input {
			display: none;
		}
	}

	.thumbnail-empty {
		width: 156px;
		aspect-ratio: 16/9;
		border: 1px dashed #979797;
		display: flex;
		justify-content: center;
		align-items: center;
		border-radius: 6px;
		padding-left: 0;
	}

	@include respond-to('medium') {
		display: grid;
		grid-template-areas:
			'title video'
			'description video'
			'thumbnail video';
		grid-template-columns: 1fr 1fr;
		column-gap: 20px;
	}
}

.thumbnail-container {
	aspect-ratio: 16/9;
	background: #000000;
	max-width: 156px;
	display: flex;
	justify-content: center;
	align-items: center;
	margin-bottom: 5px;
	height: min-content;

	img {
		max-width: 100%;
		max-height: 100%;
	}
}

.thumbnail-canvas {
	border: 1px solid red;
}

.file-list {
	margin: 0;
	padding: 0;
	list-style: none;
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	grid-template-rows: min-content;
	gap: 20px;
	z-index: 100;
	height: 100%;
	// max-height: 75vh;
	overflow: auto;

	@include respond-to('small') {
		grid-template-columns: repeat(3, 1fr);
	}

	@include respond-to('medium') {
		grid-template-columns: repeat(4, 1fr);
	}
}

.file-item {
	border-radius: 11px;
	aspect-ratio: 1;
}

.video-selector {
	&-header {
		padding: 15px 22px;
		border-bottom: 1px solid #a6a6a6;
	}

	&-preview-content {
		padding: 40px 22px;
	}

	&-footer {
		padding: 15px;
		border-top: 2px solid #222222;
		text-align: center;
		display: flex;
		justify-content: center;
		align-items: center;
	}
}

.selected-video {
	border: 2px solid #3ea6ff;
}

.not-selected-video {
	opacity: 0.6;
}

.stream-summary {
	padding: 0;

	.video-thumb {
		object-fit: contain;
		width: 100%;
		height: 100%;

		&-container {
			background: #000000;
			width: 100%;
			aspect-ratio: 16 / 9;
			display: flex;
			justify-content: center;
			align-items: center;
		}
	}

	&-content {
		padding: 15px 30px;
	}

	&-meta-info {
		display: flex;
		justify-content: space-between;
		align-items: center;
	}

	.channel-name {
		display: flex;
		align-items: center;
	}

	.channel-profile-image {
		width: 14px;
		height: 14px;
		border-radius: 50%;
		margin-right: 10px;
	}

	&-visibility {
		display: flex;
		align-items: center;
		text-transform: capitalize;

		svg {
			margin-right: 10px;
		}
	}

	&-info-container {
		margin: 5px 0;
		display: flex;
		flex-direction: row;
		gap: 8px;
	}

	&-info-thumb {
		width: 156px;
	}

	&-title {
		font-size: 24px;
		font-weight: 600;
		margin: 0;
	}

	&-description,
	&-duration {
		margin: 0;
	}

	&-duration {
		font-size: 14px;
	}
}

.visibility-icon {
	&.public {
		fill: #67d30b;
	}

	&.private {
		fill: var(--rodeoRed);
	}

	&.unlisted {
		fill: var(--lightGrey);
	}
}

.connecting {
	margin: 0 auto;
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 10px;
	font-size: 18px;
	text-align: center;

	&-footer {
		text-align: center;
		padding-bottom: 35px;
		border-top: 0;
	}
}

.stream-time {
	&-option {
		background: #212121;
		border-radius: 5px;
		width: 210px;
		height: 116px;
		display: flex;
		justify-content: center;
		align-items: center;
		border: 2px solid transparent;
		cursor: pointer;

		&:hover {
			border-color: #000000;
		}

		&-selected {
			border-color: var(--youTubeBlue);

			&:hover {
				border-color: var(--youTubeBlue);
			}
		}

		&-list {
			margin: 0;
			padding: 0;
			list-style: none;
			display: flex;
			justify-content: center;
			gap: 15px;
		}
	}

	&-subtext-promo {
		font-size: 14px;
		text-align: center;
		font-style: italic;
	}

	&-content {
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: column;
		gap: 8px;
	}

	&-name {
		font-size: 20px;
		font-weight: 600;
		display: block;
	}

	&-description {
		font-size: 11px;
		font-weight: 400;
		color: #aaaaaa;
		display: block;
	}
}

.selection-separator {
	color: #606060;
	font-size: 18px;
	font-weight: 800;
	width: 100%;
	display: block;
	margin: 18px auto;
	text-align: center;

	&::before,
	&::after {
		content: '';
		width: 15%;
		height: 1px;
		background: #606060;
		position: relative;
		vertical-align: middle;
		display: inline-block;
	}

	&::before {
		right: 0.5em;
		margin-left: -50%;
	}

	&::after {
		left: 0.5em;
		margin-right: -50%;
	}

	@include respond-to('medium') {
		width: calc(100% - 260px);

		&::before,
		&::after {
			width: 33%;
		}

		&::before {
			right: 1.5em;
		}

		&::after {
			left: 1.5em;
		}
	}
}

.scheduled-broadcast {
	&-list {
		margin: 0;
		padding: 0;
		list-style: none;
		max-height: calc(78px * 2);
		overflow: auto;
	}

	&-item {
		background: #212121;
		margin: 5px 0;
		padding: 8px 10px;
		border-radius: 4px;
		height: 68px;
		display: flex;
		align-items: center;
		border: 2px solid transparent;

		&-details {
		}

		&-title {
			font-size: 16px;
			font-weight: 600;
			display: block;
		}

		&-date {
			font-size: 11px;
			font-weight: 400;
			color: #aaaaaa;
			display: block;
		}

		&-selected {
			border-color: var(--youTubeBlue);
		}

		@include respond-to('medium') {
			min-width: 500px;
		}
	}
}

.broadcast-thumbnail {
	max-height: 53px;
	margin-right: 10px;
}

// So many buttons. How to get this into the action button styles
.go-back-button {
	background: var(--youTubeBlue);
	border-radius: 5px;
	width: 75%;
	margin: 10px auto;
	min-height: 66px;
	color: #ffffff;
	font-size: 16px;
	font-weight: 600;
	border: none;
	outline: none;
	cursor: pointer;
	text-decoration: none;
	display: block;
	line-height: 66px;
	text-align: center;
	font-family: 'YouTube Sans Dark';
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 0 10px;
	line-height: 1.7;
}
