.record-button {
	--backgroundColor: #ffffff;
	--textColor: #262626;
	background: var(--backgroundColor);
	outline: none;
	border: none;
	border-radius: 19px;
	height: 40px;
	width: 190px;
	padding: 9px 20px;
	text-align: center;
	display: flex;
	gap: 8px;
	justify-content: center;
	align-items: center;
	overflow: hidden;
	cursor: pointer;
	color: var(--textColor);
	padding: 0;
	transition: color 125ms ease-in-out, background 125ms ease-in-out,
		opacity 125ms ease-in-out;

	.button-text {
		font-weight: 500;
		font-size: 12px;
	}

	.hover-text {
		display: none;
	}

	&:hover {
		.hover-text {
			display: block;
		}

		.normal-text {
			display: none;
		}
	}
}

.record-icon {
	width: 22px;
	height: 22px;
	display: flex;
	justify-content: center;
	align-items: center;
	// background: linear-gradient(
	// 	45deg,
	// 	rgba(247, 181, 0, 1) 0%,
	// 	rgba(182, 32, 224, 1) 50%,
	// 	rgba(50, 197, 255, 1) 100%
	// );
	background: #ffffff;
	background-size: 110%;
	border-radius: 50%;

	&-center {
		width: 8px;
		height: 8px;
		background: var(--rodeoRed);
		display: block;
		border-radius: 50%;
		transform: scale(1);
	}
}

.not-started {
	--backgroundColor: rgba(0, 0, 0, 0.7);
	--textColor: #ffffff;

	&:hover {
		--backgroundColor: var(--rodeoRed);
		--textColor: #ffffff;

		.record-icon {
			background: #ffffff;

			&-center {
				background: var(--rodeoRed);
				animation: pulse 600ms ease alternate infinite;
			}
		}
	}
}

.paused {
	--backgroundColor: #525252;
	--textColor: #ffffff;
	&:hover {
		.record-icon {
			background: #ffffff;

			&-center {
				background: var(--rodeoRed);
				animation: pulse 600ms ease alternate infinite;
			}
		}
	}

	.record-icon {
		background: #ffffff;
		box-shadow: 0 0 3px rgba(0, 0, 0, 0.5);

		&-center {
			// border-radius: 2px;
			background: var(--rodeoRed);
		}
	}
}

.recording {
	--backgroundColor: var(--rodeoRed);
	--textColor: #ffffff;

	.record-icon {
		background: #ffffff;
		box-shadow: 0 0 3px rgba(0, 0, 0, 0.5);

		&-center {
			border-radius: 2px;
			background: var(--rodeoRed);
		}
	}

	&:hover {
		// --backgroundColor: #ffffff;
		// --textColor: #262626;
	}
}

@keyframes pulse {
	0% {
		transform: scale(1);
	}

	100% {
		transform: scale(2.25);
	}
}
