@import '../../../../style/_mixins';

.lightbox {
	background: rgba(0, 0, 0, 0.9);
	width: 100%;
	height: 100%;
	position: fixed;
	top: 0;
	left: 0;
	z-index: 2000;
	color: #ffffff;
	font-size: 17px;
	padding: 50px 20px;
	@include fadeInAnimation();

	@include respond-to('medium') {
		padding: 25px 45px;
	}

	&-button {
		background: none;
		outline: none;
		border: none;
		cursor: pointer;
		padding: 0;

		&:disabled {
			pointer-events: none;
		}

		.button-text {
			position: absolute;
			left: 100%;
		}
	}

	&-content {
		display: flex;
		justify-content: space-between;
		flex-direction: column;
		max-height: calc(100vh - 100px);
		overflow: auto;
	}

	&.closing {
		@include fadeOutAnimation();
	}
}

.close-icon {
	position: absolute;
	top: 25px;
	right: 25px;
	cursor: pointer;
	z-index: 100;
}
