.row-group {
	display: flex;
	align-items: center;
}

.row {
	flex-direction: row;
	justify-content: space-between;
}

.column {
	flex-direction: column;
}

.align {
	&-left {
		justify-content: flex-start;
		justify-self: start;
		align-items: flex-end;
	}
	&-right {
		justify-content: flex-end;
		justify-self: end;
		align-items: flex-end;
	}
}

.full {
	width: 100%;
	justify-content: space-between;
	> * {
		flex-grow: 1;
	}
}

.with-gap {
	gap: 40px;
}

.wrap {
	flex-flow: wrap;
}
