.permission-container {
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
}

.indicator-badge {
	width: 18px;
	height: 18px;
	display: block;
	border-radius: 50%;
	background: #cc1005;
	display: flex;
	justify-content: center;
	align-items: center;
	position: absolute;
	bottom: 0;
	right: 0;

	&::before {
		content: '\00D7';
		color: #ffffff;
		display: block;
		font-size: 12px;
	}

	&.granted {
		background: #42cc05;
		&::before {
			content: '\2713';
		}
	}
}
