.link {
	display: flex;
}
.logo {
	width: 100px;
}

.title {
	position: fixed;
	bottom: 36px;
	left: 100%;
}
