.upload {
	z-index: 100;
	input {
		display: none;
	}
	label {
		text-align: center;
		width: 280px;
		height: 60px;
		background: #ffffff;
		display: block;
		color: #000000;
		border-radius: 30px;
		line-height: 60px;
		font-size: 16px;
		font-weight: 600;
		cursor: pointer;
		border: 1px solid transparent;
		box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.3);

		&::before {
			content: '';
			background: linear-gradient(
				135deg,
				#32c5ff 0%,
				#b620e0 50%,
				#f7b500 100%
			);
			background-size: 110%;
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
			margin: -2px;
			border-radius: inherit;
			z-index: -1;
		}
	}
}
