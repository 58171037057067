@mixin rotationAnimation() {
	animation: rotateAround 1000ms linear infinite;
}

@mixin fadeInAnimation() {
	animation: fadeIn 250ms both normal var(--easing);
}

@mixin fadeOutAnimation() {
	animation: fadeOut 250ms both normal var(--easing);
}

$breakpoints: (
	'small': (
		min-width: 600px,
	),
	'medium': (
		min-width: 800px,
	),
	'large': (
		min-width: 1440px,
	),
) !default;

/// Mixin to manage responsive breakpoints
/// @author Kitty Giraudel
/// @param {String} $breakpoint - Breakpoint name
/// @require $breakpoints
@mixin respond-to($breakpoint) {
	// If the key exists in the map
	@if map-has-key($breakpoints, $breakpoint) {
		// Prints a media query based on the value
		@media #{inspect(map-get($breakpoints, $breakpoint))} {
			@content;
		}
	}

	// If the key doesn't exist in the map
	@else {
		@warn "Unfortunately, no value could be retrieved from `#{$breakpoint}`. "
        + "Available breakpoints are: #{map-keys($breakpoints)}.";
	}
}

@mixin contentContainer() {
	@include respond-to('large') {
		padding-left: calc((100% - var(--largeMinWidth)) / 2);
		padding-right: calc((100% - var(--largeMinWidth)) / 2);
	}
}

@keyframes rotateAround {
	0% {
		transform: rotate(0);
	}
	100% {
		transform: rotate(360deg);
	}
}

@keyframes fadeIn {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

@keyframes fadeOut {
	0% {
		opacity: 1;
	}
	100% {
		opacity: 0;
	}
}
