@import '../../../../style/_mixins';

.smart-folder {
	&-container {
		aspect-ratio: 1;
		overflow: hidden;
		border-radius: 11px;
		box-shadow: var(--itemShadow);
		padding: 10px;
		cursor: pointer;

		img {
			max-width: 100%;
			max-height: 100%;
			cursor: pointer;
		}
	}

	&-type {
		position: absolute;
		bottom: 15px;
		right: 20px;
		font-size: 16px;
		color: #ffffff;
		text-transform: capitalize;
		font-weight: 600;
		text-shadow: 0 0 4px rgba(0, 0, 0, 0.5);
		font-family: 'Roboto', sans-serif;
	}
}

.folder-item {
	background: #262626;
	padding: 15px;
	border-radius: 8px;
	margin: 5px auto;

	&-list {
		list-style: none;
		margin: 0 auto;
		padding: 0;
		width: 100%;

		@include respond-to('medium') {
			width: clamp(300px, 80%, 600px);
		}
	}
}

.audio-folder {
	position: relative;
}

.images-folder,
.GIF-folder {
	width: 90%;
	background: #262626;
	border-radius: 8px;
	margin-top: 30px;
	display: flex;
	flex-flow: row wrap;
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	gap: 20px;

	@include respond-to('small') {
		grid-template-columns: repeat(3, 1fr);
	}

	@include respond-to('medium') {
		grid-template-columns: repeat(4, 1fr);
	}

	@include respond-to('large') {
		grid-template-columns: repeat(5, 1fr);
	}

	.folder-item {
		margin: 0;
		aspect-ratio: 1;
		display: flex;
		// overflow: hidden;
		border-radius: 11px;
	}
}

.image-preview {
	&-container {
		display: grid;
		grid-template-columns: repeat(2, 1fr);
		grid-template-rows: repeat(2, 1fr);
		gap: 6px;
	}

	&-item {
		border-radius: 8px;
		aspect-ratio: 1;
		overflow: hidden;

		img {
			object-fit: cover;
			object-position: 50% 50%;
			width: 100%;
			height: 100%;
			border-radius: 8px;
		}
	}
}

.image-remainder {
	background: #90c455;
	display: flex;
	justify-content: center;
	align-items: center;
	color: #ffffff;
	font-size: 24px;
	font-weight: 600;
}
