.dropzone {
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	opacity: 0;
	&.drag-active {
		opacity: 1;
		z-index: 100000;
		*,
		~ *,
		+ * {
			pointer-events: none;
		}
	}
}
