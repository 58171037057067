.scheduled-broadcast {
	&-container {
		position: fixed;
		bottom: 18px;
		right: 10px;
		max-width: 460px;
		// width: 300px;
		border-radius: 6px;
		overflow: hidden;
		color: #ffffff;
		z-index: 500;
		transition: height 125ms ease-in-out;
	}

	&-header {
		background: #282828;
		padding: 14px;
		color: #ffffff;
		z-index: 500;

		h2 {
			margin: 0;
			font-weight: 600;
			white-space: nowrap;
			text-overflow: ellipsis;
			overflow: hidden;
		}

		button {
			position: absolute;
			right: 10px;
			top: 50%;
			transform: translateY(-50%);
			background: transparent;
			outline: none;
			border: none;
			cursor: pointer;
			height: 20px;
			width: 30px;
			overflow: hidden;

			&::before,
			&::after {
				content: '';
				background: #ffffff;
				display: block;
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);
			}

			&::before {
				width: 10px;
				height: 2px;
			}

			&::after {
				display: none;
				width: 2px;
				height: 10px;
			}

			&.minimized {
				&::after {
					display: block;
				}
			}

			&:hover {
				&::before,
				&::after {
					background: var(--youTubeBlue);
				}
			}

			.button-text {
				position: absolute;
				left: 100%;
			}
		}
	}

	&-preview-content {
		img {
			width: auto;
			height: auto;
			max-width: 100%;
			max-height: 100%;
			display: inherit;
		}
	}

	&-details {
		background: rgba(0, 0, 0, 0.8);
		border: 1px solid #000000;
		border-top-left-radius: 6px;
		border-bottom-left-radius: 6px;
		position: absolute;
		bottom: 24px;
		right: 0;
		padding: 8px 18px;

		span {
			display: block;
		}
	}

	&-action {
		opacity: 0;
		width: 100%;
		height: 100%;
		position: absolute;
		top: 0;
		left: 0;
		background: #000000;
		display: flex;
		justify-content: center;
		align-items: center;
		transition: opacity 0.25s ease-in-out;

		button {
			cursor: pointer;
		}

		&:hover {
			opacity: 1;
		}
	}
}
