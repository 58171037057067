@import '../../../../style/_mixins';

.action-button {
	background: none;
	outline: none;
	border: none;
	cursor: pointer;
	padding: 0;
	display: flex;
	width: auto;
	border-radius: 6px;
	height: 45px;
	line-height: 45px;

	&:disabled {
		pointer-events: none;
		opacity: 0.7;
	}

	.button-text {
		width: 100%;
		font-size: 16px;
		font-weight: 600;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
	}

	&.icon-button {
		width: 26px;
		overflow: hidden;
		position: relative;
		path {
			transition: fill 250ms var(--easing);
			fill: #525252;
		}

		&:hover {
			path {
				fill: var(--rodeoRed);
			}
		}
		.button-text {
			position: absolute;
			left: 100%;
		}
	}

	&.icon-text-button {
		align-items: center;
	}

	svg {
		margin-right: 5px;
	}

	@include respond-to('large') {
		svg {
			margin-right: 15px;
		}
	}
}

.align-left {
	text-align: left;
}

.align-center {
	text-align: center;
}

.align-right {
	text-align: right;
}

.type {
	&-normal {
	}

	&-warning {
		color: #ffffff;
		background: #cc0000;
	}

	&-action {
		background: var(--youTubeBlue);
		color: #ffffff;
	}
}

.display {
	&-transparent {
		background: transparent;
		// color: #000000;
	}

	&-pill {
	}

	&-standard {
		font-weight: 900;
		font-size: 16px;
		padding: 0 30px;
	}
}

.light-text {
	color: #ffffff;
	font-family: 'Youtube Sans Dark';

	&.type-normal:not(.display-transparent) {
		background: #434343;
	}

	&.trans path {
		fill: #ffffff;
	}

	g {
		stroke: #ffffff;
	}

	&.icon-fill g {
		fill: #ffffff;
	}
}

.dark-text {
	color: #000000;
	font-family: 'Youtube Sans';
}

.small-width {
	padding-left: 10px;
	padding-right: 10px;
}

.large-width {
	@include respond-to('medium') {
		padding-left: 90px;
		padding-right: 90px;
	}
}

.full-width {
	width: 100%;
}
