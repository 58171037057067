.toast-notification {
	--color: #000000;
	--bgColor: #ffffff;

	&-container {
		position: fixed;
		top: 0;
		left: 50%;
		transform: translateX(-50%);
		z-index: 10000;
		border: 1px solid var(--color);
		border-bottom-left-radius: 8px;
		border-bottom-right-radius: 8px;
		padding: 20px;
		text-align: center;
		color: var(--color);
		background: var(--bgColor);
		border-top: 0;
	}

	&-info {
		--color: #3ea6ff;
		--bgColor: #d0e9ff;
	}

	&-warning {
		--color: var(--rodeoYellow);
		--bgColor: #fdedc2;
	}

	&-error {
		--color: var(--rodeoRed);
		--bgColor: #f6cecb;
	}

	&-success {
		--color: var(--rodeoGreen);
		--bgColor: #dcf5c2;
	}
}
