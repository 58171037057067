.popup-panel {
	&-wrapper {
		border-radius: 8px;
		box-shadow: var(--boxShadow);
		background: #ffffff;
		padding: 20px 24px;
	}
	&-title {
		font-size: 16px;
		font-weight: 600;
	}
}
