.exporter {
	&-overlay {
		background: linear-gradient(0, #000000 0, transparent 12%),
			rgba(0, 0, 0, 0.2);
		backdrop-filter: blur(20px);
		width: 100%;
		height: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: column;
		text-align: center;
		color: #efefef;
		padding: 40px 20px;
		font-size: 24px;
		font-weight: 900;
	}

	&-content {
		display: flex;
		align-items: center;
		flex-direction: column;
	}

	&-heading {
		font-size: 32px;
		font-weight: 300;
		margin: -30px 0 0;
	}

	&-subhead {
		font-size: 24px;
		margin: 10px 0 50px;
	}

	&-meta {
		position: absolute;
		bottom: 50px;
		font-size: 16px;
		display: flex;
		gap: 40px;

		&-value {
			font-weight: 600;
		}
	}
}

.progress-bar-container {
	width: 300px;
}

.restart-button {
	margin-top: 40px;
}
