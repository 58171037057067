.control-button {
	--backgroundColor: #ffffff;
	--textColor: #262626;
	--borderColor: var(--backgroundColor);
	background: var(--backgroundColor);
	outline: none;
	border: none;
	overflow: hidden;
	cursor: pointer;
	color: var(--textColor);
	padding: 0;
	transition: color 125ms ease-in-out, background 125ms ease-in-out,
		border 125ms ease-in-out, opacity 125ms ease-in-out;
	font-size: 9px;

	.button-text {
		font-weight: 500;
		font-size: inherit;
	}

	&.hide-label {
		.button-text {
			position: absolute;
			left: 150%;
		}
	}

	&:disabled {
		opacity: 0.2;
		pointer-events: none;
	}

	// path {
	// 	fill: var(--textColor);
	// }

	// rect {
	// 	strok
	// }

	&.active {
		--backgroundColor: #f4f4f4;
	}
}

// style
.icon {
	--backgroundColor: transparent;

	&-left {
	}
	&-right {
		svg {
			order: 2;
		}
	}
	&-top {
		display: flex;
		flex-direction: column;
		align-items: center;
		height: 40px;
		justify-content: space-between;

		.button-text {
			// margin-top: 2px;
		}
	}
	&-full {
	}

	&-circle {
		border-radius: 50%;
		aspect-ratio: 1;
	}
}

.pill {
	--borderColor: #e9e9e9;
	border: 1px solid var(--borderColor);
	border-radius: 19px;
	height: 32px;
	min-width: 90px;
	padding: 9px 22px;
	text-align: center;
	display: flex;
	gap: 8px;
	justify-content: center;
	align-items: center;

	.button-text {
		position: relative;
		left: auto;
	}

	&:hover {
		--textColor: #ffffff;
		--backgroundColor: #000000;
		svg {
			filter: invert(1);
		}
	}
}

.rectangle {
	border-radius: 6px;
	height: 37px;
	text-align: center;
	display: flex;
	gap: 8px;
	justify-content: center;
	align-items: center;

	.button-text {
		position: relative;
		left: auto;
	}
}

.cta,
.confirm {
	--backgroundColor: #1f85ff;
	--borderColor: var(--backgroundColor);
	--textColor: #ffffff;
	border: 1px solid var(--borderColor);
	border-radius: 19px;
	height: 38px;
	min-width: 90px;
	padding: 9px 22px;
	text-align: center;
	display: flex;
	gap: 8px;
	justify-content: center;
	align-items: center;

	.button-text {
		font-size: 14px;
		font-weight: 500;
		position: relative;
		left: auto;
	}

	&:hover {
		--backgroundColor: #1a4d8b;
		svg {
			filter: invert(1);
		}
	}
}

.text {
	--backgroundColor: transparent;
	--textColor: #ffffff;
	text-shadow: var(--boxShadow);

	.button-text {
		font-size: 16px;
		font-weight: 500;
	}
}

.transparent {
	border: none;
	background: none;
	text-align: center;
	display: flex;
	gap: 8px;
	justify-content: center;
	align-items: center;

	&.pill {
		padding: 0;
	}

	.button-text {
		position: relative;
		left: auto;
	}

	&:hover {
		--textColor: #000000;
		svg {
			filter: none;
		}
	}
}

.outline {
	--backgroundColor: transparent;
	--borderColor: #ffffff;
	--textColor: #ffffff;

	&:hover {
		--backgroundColor: #ffffff;
		--textColor: #262626;
	}
}

.confirm {
	--backgroundColor: var(--rodeoRed);
	--textColor: #ffffff;

	&:hover {
		--backgroundColor: #ef635b;
	}
}

// color
.light {
}

.dark {
	// background: #0481fd;
	--textColor: #ffffff;

	&:hover {
		--backgroundColor: #ffffff;
		--textColor: #262626;
	}
}

.dark-translucent {
	--backgroundColor: rgba(0, 0, 0, 0.6);
	--borderColor: var(--backgroundColor);
	--textColor: #ffffff;

	&:hover {
		--backgroundColor: rgba(0, 0, 0, 1);
		--borderColor: #ffffff;
		svg {
			filter: none;
		}
	}
}
