.stage-wrapper {
	--zPlaneIndex: 1000;
	width: 100%;
	height: 100%;
	background: #000000;

	&::before {
		content: '';
		background: linear-gradient(
			180deg,
			rgba(0, 0, 0, 0) 0%,
			rgba(0, 0, 0, 0.8) 100%
		);
		position: absolute;
		bottom: 0;
		left: 0;
		width: 100%;
		height: 77px;
		z-index: calc(var(--zPlaneIndex) - 1);
		pointer-events: none;
	}

	&.adding-files-from-computer {
		&::before {
			content: none;
		}
	}

	&.special-cursor {
		cursor: none;
	}

	&:hover {
		.fit-fill-button {
			display: flex;
		}

		.aspect-ratio-button {
			display: block;
		}
	}
}

.record-button {
	font-size: 16px;

	&-container {
		position: absolute;
		top: 24px;
		left: 50%;
		transform: translate(-50%, 0);
		view-transition-name: record-button;

		&.centered {
			top: 50%;
			transform: translate(-50%, -50%);
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			gap: 24px;
		}
	}
}
::view-transition-old(record-button),
::view-transition-new(record-button) {
	transition: top 6s ease-in-out;
	// transition-duration: 5s;
}

.layout-buttons-container {
	position: absolute;
	bottom: 24px;
	left: 50%;
	transform: translateX(-50%);
	display: flex;
	gap: 20px;
	z-index: calc(var(--zPlaneIndex) * 10);
}

.z-plane-item {
	z-index: var(--zPlaneIndex);
}

.aspect-ratio-button {
	display: none;
	position: absolute;
	bottom: 15px;
	left: 15px;
}

.layouts-button {
	color: #ffffff;
	font-size: 12px;
}

.fit-fill-button {
	display: none;
	position: absolute;
	width: 34px;
	height: 34px;
	border-radius: 50%;
	border: 1px solid transparent;
	justify-content: center;
	align-items: center;

	&:hover {
		background: rgba(0, 0, 0, 0.8);
		border-color: #141414;
	}

	// Note: top, bottom, left, right refer to the camera position
	&.circle,
	&.top,
	&.left,
	&.source {
		bottom: 15px;
		right: 15px;
	}

	&.right {
		bottom: 15px;
		left: calc(50% - 82px);
	}

	&.bottom {
		top: calc(50% - 47px);
		right: 15px;
	}

	&.camera {
		display: none;
	}

	// Should this be a separate button style?
	.fit-fill-button {
	}
}

.controls-container {
	position: absolute;
	transform: translateY(-50%);
	// pointer-events: none;

	// Note: top, bottom, left, right refer to the camera position

	// width
	&.circle,
	&.top,
	&.bottom,
	&.source,
	&.flipped {
		width: 100%;
	}

	&.right,
	&.left {
		width: 50%;
	}

	// position
	&.source,
	&.circle,
	&.right,
	&.left,
	&.flipped {
		top: 50%;
	}

	&.bottom {
		top: 25%;
	}

	&.top {
		top: 75%;
	}

	&.left {
		left: 50%;
	}

	&.camera {
		display: none;
	}
}
