@import '../../../../style/_mixins';

.key {
	background: var(--nonActiveBackgroundColor);
	border: 1px solid var(--nonActiveFontColor);
	color: var(--nonActiveFontColor);
	border-radius: 4px;
	height: 18px;
	min-width: 18px;
	padding: 0 11px;
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 7px;
	cursor: pointer;

	&:hover {
		border-color: var(--activeBackgroundColor);
		background: var(--activeBackgroundColor);
		color: var(--activeFontColor);

		svg {
			filter: invert(1);
		}
	}

	&-container {
		--activeBackgroundColor: #262626;
		--nonActiveBackgroundColor: #ffffff;
		--activeFontColor: #ffffff;
		--nonActiveFontColor: #262626;
		color: var(--nonActiveFontColor);
		font-size: 9px;
		display: flex;
		flex-direction: column;
		align-items: center;
		gap: 3px;

		&.disabled {
			opacity: 0.2;
			pointer-events: none;
		}
	}

	&.square {
		width: 18px;
		padding: 0;
	}
}

.keyboard-shortcuts {
	margin: 8px 0 0;
	padding: 12px 0 0;
	list-style: none;
	display: grid;
	grid-template-columns: 1fr;
	justify-items: center;
	text-align: center;
	// grid-template-rows: 1fr 1fr;
	row-gap: 8px;
	position: absolute;
	// bottom: 100%;
	bottom: 0;
	left: 50%;
	transform: translateX(-50%);

	&-container {
		padding: 8px 15px;
		background: #ffffff;
		border-radius: 10px;
		box-shadow: 0 0 19px rgba(0, 0, 0, 0.05);
		border: 1px solid #ececec;
	}
}

.active {
	.key {
		border-color: var(--activeBackgroundColor);
		background: var(--activeBackgroundColor);
		color: var(--activeFontColor);

		svg {
			filter: invert(1);
		}
	}
}
.shortcuts-wrapper {
	position: relative;
	padding-left: 12px;

	@include respond-to('large') {
		// padding-left: 0;
	}
}

.key-label {
	white-space: nowrap;
}
