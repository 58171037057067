.av-settings-list {
	&::before {
		content: '';
		width: 18px;
		height: 18px;
		background: #ffffff;
		display: block;
		position: absolute;
		top: 18px;
		right: -9px;
		transform: rotate(45deg);
		box-shadow: var(--boxShadow);
		border-top-right-radius: 2px;
		pointer-events: none;
	}

	&::after {
		content: '';
		width: 18px;
		height: 32px;
		background: #ffffff;
		display: block;
		position: absolute;
		top: 13px;
		right: 0;
		pointer-events: none;
	}
}

.devices-list-container {
	margin: 4px 0;
	height: 32px;

	&.with-meter {
		display: grid;
		grid-template-columns: 1fr;
	}
}
