@import '../../../../style/_mixins';

.loading {
	margin: 30px auto;
	display: flex;
	flex-direction: column;
	width: 82px;
	align-items: center;
	font-weight: 600;
}
.loading-icon {
	@include rotationAnimation;
	margin-bottom: 20px;
}

.loading-text {
	white-space: nowrap;
}

.center {
	width: 156px;

	.loading-text {
		position: absolute;
		top: 45%;
		left: 50%;
		transform: translate(-50%, -50%);
		display: block;
	}
}
