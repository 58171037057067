@import '../../../../style/_mixins';

.item {
	// aspect-ratio: 1;
	// overflow: hidden;
	// border-radius: 11px;
	// position: relative;

	&-thumb,
	video {
		// object-fit: cover;
		// object-position: 50% 50%;
		// width: 100%;
		// height: 100%;
		// transition: opacity 250ms ease-in-out;
	}

	&-duration {
		position: absolute;
		bottom: 10px;
		right: 10px;
		color: #ffffff;
		font-size: 16px;
		font-weight: 600;
		text-shadow: 0 1px 2px rgb(0 0 0 / 60%), 0 0 2px rgb(0 0 0 / 30%);
	}

	.status {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		z-index: 100;
		display: flex;
		flex-direction: column;
		color: #ffffff;
		align-items: center;

		svg {
			margin-bottom: 5px;
		}
	}

	&-title {
		overflow: hidden;
		text-overflow: ellipsis;
		padding-right: 10px;
	}
}

.control-button {
	background: none;
	border: none;
	outline: none;
	position: relative;
	overflow: hidden;
	cursor: pointer;
	display: flex;

	svg {
		max-height: 100%;
		max-width: 100%;
	}

	.button-text {
		position: absolute;
		left: 200%;
	}

	&:hover {
		path {
			fill: var(--youTubeBlue);
		}
	}
}

.uploaded-icon {
	position: absolute;
	top: 5px;
	left: 5px;
}

.player-container {
	width: 100%;
	height: 180px;
	position: relative;
	overflow: hidden;

	video {
		width: 100%;
		top: 50%;
		position: absolute;
		transform: translateY(-50%);
	}
}

.audio-controls {
	--trackHeight: 4px;
	width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: center;
	gap: 20px;

	.track {
		position: relative;
		flex-grow: 1;

		.full-length {
			width: 100%;
			height: var(--trackHeight);
			border-radius: 2px;
			background: #212121;
			display: block;
			position: absolute;
			top: 0;
		}

		.played {
			height: var(--trackHeight);
			width: 0;
			background: #ffffff;
			display: block;
			z-index: 100;
			position: absolute;
			top: 0;
			border-radius: 2px;
			// transition: width 200ms linear;
		}

		// This could just be a psuedo element on played?
		// Depends if we want scrubbing abilities
		.playhead {
			width: calc(var(--trackHeight) * 1.5);
			height: calc(var(--trackHeight) + 6px);
			position: absolute;
			top: -3px;
			background: #ffffff;
			border: 1px solid #000000;
			display: block;
			z-index: 100;
			left: -3px;
			// transition: left 200ms linear;
		}
	}

	.control-buttons {
	}

	.timer {
	}

	.play {
	}

	.pause {
	}
}

.cloud-icon {
	position: absolute;
	top: 5px;
	left: 5px;
}

.audio-actions {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: center;

	.audio-buttons {
		display: flex;
		justify-content: space-between;
		align-items: center;
		gap: 10px;
	}

	@include respond-to('medium') {
		flex-direction: row;
	}
}
