.toggle {
	--onColor: #262626;
	--offColor: #5f6367;
	--trackColor: #dadce0;
	--toggleHeight: 14px;
	--toggleWidth: 34px;
	--handleHeight: 20px;
	--handleWidth: var(--handleHeight);
	--activeTextColor: #262626;
	--inactiveTextColor: #c7c7c7;
	--textGap: 6px;
	--uiMargin: calc(var(--textGap) + 13px);
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: var(--textGap);

	.label-text {
		font-size: 9px;
		transition: opacity 125ms ease-in-out;
	}

	&.label-bottom {
		.label-text {
			order: 2;
		}
		.toggle-wrapper {
			order: 1;
			// visually we want the label to be out of the grid so we add a
			// bottom margin to the toggle equivalent to the text height + gap
			// so it pushes itself up in the grid
			margin-top: var(--uiMargin);
		}
	}

	&.label-top {
		.label-text {
			order: 1;
		}
		.toggle-wrapper {
			order: 2;
			// visually we want the label to be out of the grid so we add a
			// bottom margin to the toggle equivalent to the text height + gap
			// so it pushes itself up in the grid
			margin-bottom: var(--uiMargin);
		}
	}

	&.label-hidden {
		.label-text {
			display: none;
		}
	}

	&.is-disabled {
		.label-text {
			opacity: 0.2;
		}
	}
}

.toggle-wrapper {
	margin: auto;
	width: var(--toggleWidth);
	border-radius: 5px;
	input {
		&.mobile-toggle {
			opacity: 0; // hides checkbox
			position: absolute;

			& + label {
				position: relative;
				display: flex;
				justify-content: space-around;
				align-items: center;
				user-select: none;
				transition: 400ms ease, opacity 125ms ease-in-out;
				tap-highlight-color: transparent;
				height: var(--toggleHeight);
				width: var(--toggleWidth);
				// border: 2px solid var(--onColor);
				border-radius: 60px;
				cursor: pointer;

				span {
					z-index: 100;
				}

				&::before {
					content: '';
					background: var(--trackColor);
					position: absolute;
					display: block;
					transition: 200ms cubic-bezier(0.24, 0, 0.5, 1);
					height: calc(var(--toggleHeight) - 4px);
					width: calc(var(--toggleWidth) - 4px);
					top: 0;
					left: 0;
					border-radius: 30px;
					// box-shadow: var(--boxShadow);
				}

				.handle {
					position: absolute;
					display: flex;
					justify-content: center;
					align-items: center;
					// box-shadow: 0 0 0 1px hsla(0, 0%, 0%, 0.1),
					// 	0 4px 0px 0 hsla(0, 0%, 0%, 0.04),
					// 	0 4px 9px hsla(0, 0%, 0%, 0.13),
					// 	0 3px 3px hsla(0, 0%, 0%, 0.05);
					transition: 350ms cubic-bezier(0.54, 1.6, 0.5, 1);
					background: var(--offColor);
					height: var(--handleHeight);
					width: var(--handleWidth);
					top: calc(var(--handleHeight) / 4 * -1);
					left: -2px;
					border-radius: 50%;
					box-shadow: var(--boxShadow);
				}
			}
			// When Active
			&:checked {
				& + label {
					&::before {
						transition: width 200ms cubic-bezier(0, 0, 0, 0.1);
					}
					.handle {
						left: calc(
							var(--toggleWidth) - var(--handleWidth) - 4px
						);
						background: var(--onColor);
					}

					.off-text {
						opacity: 0;
						transition-duration: 100ms;
						transition-delay: 0s;
					}

					.on-text {
						opacity: 1;
						transition-delay: 300ms;
						transition-duration: 100ms;
					}
				}
			}

			&:disabled {
				& + label {
					opacity: 0.2;
					pointer-events: none;
				}
			}
		}
	}
	.on-text,
	.off-text {
		color: #ffffff;
		font-size: 9px;
		position: absolute;
		transition: opacity 100ms 300ms ease-in-out;
		opacity: 1;
	}

	.off-text {
	}

	.on-text {
		opacity: 0;
		transition-duration: 100ms;
		transition-delay: 0s;
	}
}
